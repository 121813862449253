import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const pathSegments = location.pathname.split('/');
    const currentLang = pathSegments[1]; 

    if (currentLang && i18n.hasResourceBundle(currentLang, 'translation')) {
      i18n.changeLanguage(currentLang); 
      window.localStorage.setItem('language', JSON.stringify({ lang: currentLang }));
    } else {
      i18n.changeLanguage('en'); 
      window.localStorage.setItem('language', JSON.stringify({ lang: 'en' })); 
    }
  }, [location.pathname]); 

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    window.localStorage.setItem('language', JSON.stringify({ lang: lng })); 
    setIsOpen(false); // Close dropdown after selection
    window.history.pushState(null, '', `/${lng}`); // Update the URL without reloading the page
  };

  return (
    <div className="relative inline-block text-left">
      <div>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="flex items-center justify-between w-full rounded-md shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
        >
          <span>{i18n.t('language')}</span>
          <svg className="ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
          </svg>
        </button>
      </div>

      {isOpen && (
        <div className="absolute right-0 z-10 mt-2 w-48 rounded-md bg-white shadow-lg">
          <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            <button onClick={() => changeLanguage('en')} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">
              {i18n.t('languages.en')}
            </button>
            <button onClick={() => changeLanguage('de')} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">
              {i18n.t('languages.de')}
            </button>
            <button onClick={() => changeLanguage('zh')} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">
              {i18n.t('languages.zh')}
            </button>
            <button onClick={() => changeLanguage('fr')} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">
              {i18n.t('languages.fr')}
            </button>
            <button onClick={() => changeLanguage('nl')} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">
              {i18n.t('languages.nl')}
            </button>
            <button onClick={() => changeLanguage('tr')} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">
              {i18n.t('languages.tr')}
            </button>
            <button onClick={() => changeLanguage('fi')} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">
              {i18n.t('languages.fi')}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default LanguageSwitcher;