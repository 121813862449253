import FaqComponent from "./FaqComponent";
import { useTranslation } from 'react-i18next';

function FaqList(props: any) {
  const { t } = useTranslation();
  return (
    <div className="p-4 mt-20 lg:mx-20">
      <h1>FAQ (How it works)</h1>
      <div
        className="mt-3 mb-3"
        style={{ borderBottomWidth: "1px", borderBottomColor: "lightgray" }}
      />
      <FaqComponent
        faq={t("FaqList.faq")}
        answer={t("FaqList.answer")}
      />
      <FaqComponent
        faq={t("FaqList.faq2")}
        answer={t("FaqList.answer2")}
      />
      <FaqComponent
        faq={t("FaqList.faq3")}
        answer={t("FaqList.answer3")}
      />
      <FaqComponent
        faq={t("FaqList.faq4")}
        answer={t("FaqList.answer4")}
      />
      <FaqComponent
        faq={t("FaqList.faq5")}
        answer={t("FaqList.answer5")}
      />
      <FaqComponent
        faq={t("FaqList.faq6")}
        answer={t("FaqList.answer6")}
      />
      <FaqComponent
        faq={t("FaqList.faq7")}
        answer={t("FaqList.answer7")}
      />
    </div>
  );
}

export default FaqList;
